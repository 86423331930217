interface TStep {
  title: string;
  description: string;
}

const STEPS: TStep[] = [
  {
    title: 'Постановка задачи',
    description: 'Обсуждаем с вами ваш бизнес-процесс и задачу. Общаемся и узнаем потребности.'
  },
  {
    title: 'Техническое задание',
    description: 'Интерпретируем вашу потребность в техническое задание, выбираем стек технологий.'
  },
  {
    title: 'Коммерческое предложение',
    description:
      'Обсуждаем и корректируем элементы будущего решения, формируем коммерческое предложение.'
  },
  {
    title: 'Заключение договора',
    description:
      'Ведём переговоры о сумме и сроках выполнения работ. Заключаем договор и начинаем' +
      ' сотрудничество.'
  },
  {
    title: 'Запуск продукта',
    description:
      'Завершаем работы и демонстрируем финальный результат, запускаем ваш новый продукт.'
  },
  {
    title: 'Поддержка продукта',
    description: 'Обучаем ваших сотрудников и поддерживаем работу в течение всей жизни экосистемы.'
  }
];

export default STEPS;
