type TText = {
  number?: number;
  text: string;
};

type TRow = {
  cell: string;
  cell2: string;
};

interface TPolicy {
  title: string;
  subtitle?: string;
  texts?: TText[];
  table?: TRow[];
}

const POLITICS: TPolicy[] = [
  {
    title: 'Общие положения',
    subtitle:
      'Настоящая политика обработки персональных данных составлена в соответствии с требованиями' +
      ' Федерального закона от 27.07.2006. № 152-ФЗ «О персональных данных» ' +
      '(далее — Закон о персональных данных) и определяет порядок обработки персональных ' +
      'данных и меры по обеспечению безопасности персональных данных, предпринимаемые' +
      ' ООО "РИТМ" (далее — Оператор).',
    texts: [
      {
        number: 1,
        text:
          'Оператор ставит своей важнейшей целью и условием осуществления своей ' +
          'деятельности соблюдение прав и свобод человека и гражданина при обработке ' +
          'его персональных данных, в том числе защиты прав на неприкосновенность ' +
          'частной жизни, личную и семейную тайну.'
      },
      {
        number: 2,
        text:
          'Настоящая политика Оператора в отношении обработки персональных данных ' +
          '(далее — Политика) применяется ко всей информации, которую Оператор может ' +
          'получить о посетителях веб-сайта https://ritm.studio.'
      }
    ]
  },
  {
    title: 'Основные понятия, используемые в Политике',
    texts: [
      {
        number: 1,
        text:
          'Автоматизированная обработка персональных данных — обработка ' +
          'персональных данных с помощью средств вычислительной техники.'
      },
      {
        number: 2,
        text:
          'Блокирование персональных данных — временное прекращение обработки ' +
          'персональных данных (за исключением случаев, если обработка необходима ' +
          'для уточнения персональных данных).'
      },
      {
        number: 3,
        text:
          'Веб-сайт — совокупность графических и информационных материалов, а ' +
          'также программ для ЭВМ и баз данных, обеспечивающих их доступность в ' +
          'сети интернет по сетевому адресу https://ritm.studio.'
      },
      {
        number: 4,
        text:
          'Информационная система персональных данных — совокупность содержащихся ' +
          'в базах данных персональных данных и обеспечивающих их обработку ' +
          'информационных технологий и технических средств.'
      },
      {
        number: 5,
        text:
          'Обезличивание персональных данных — действия, в результате которых ' +
          'невозможно определить без использования дополнительной информации ' +
          'принадлежность персональных данных конкретному Пользователю или ' +
          'иному субъекту персональных данных.'
      },
      {
        number: 6,
        text:
          'Обработка персональных данных — любое действие (операция) или ' +
          'совокупность действий (операций), совершаемых с использованием ' +
          'средств автоматизации или без использования таких средств с ' +
          'персональными данными, включая сбор, запись, систематизацию, ' +
          'накопление, хранение, уточнение (обновление, изменение), извлечение, ' +
          'использование, передачу (распространение, предоставление, доступ), ' +
          'обезличивание, блокирование, удаление, уничтожение персональных данных.'
      },
      {
        number: 7,
        text:
          'Оператор — государственный орган, муниципальный орган, юридическое ' +
          'или физическое лицо, самостоятельно или совместно с другими лицами ' +
          'организующие и/или осуществляющие обработку персональных данных, а ' +
          'также определяющие цели обработки персональных данных, состав ' +
          'персональных данных, подлежащих обработке, действия (операции), ' +
          'совершаемые с персональными данными.'
      },
      {
        number: 8,
        text:
          'Персональные данные — любая информация, относящаяся прямо или ' +
          'косвенно к определенному или определяемому Пользователю ' +
          'веб-сайта https://ritm.studio.'
      },
      {
        number: 9,
        text:
          'Персональные данные, разрешенные субъектом персональных данных ' +
          'для распространения, — персональные данные, доступ неограниченного ' +
          'круга лиц к которым предоставлен субъектом персональных данных путем ' +
          'дачи согласия на обработку персональных данных, разрешенных субъектом ' +
          'персональных данных для распространения в порядке, предусмотренном Законом ' +
          'о персональных данных (далее — персональные данные, ' +
          'разрешенные для распространения).'
      },
      {
        number: 10,
        text: 'Пользователь — любой посетитель веб-сайта https://ritm.studio.'
      },
      {
        number: 11,
        text:
          'Предоставление персональных данных — действия, направленные на ' +
          'раскрытие персональных данных определенному лицу или определенному кругу лиц.'
      },
      {
        number: 12,
        text:
          'Распространение персональных данных — любые действия, направленные ' +
          'на раскрытие персональных данных неопределенному кругу лиц ' +
          '(передача персональных данных) или на ознакомление с персональными ' +
          'данными неограниченного круга лиц, в том числе обнародование персональных ' +
          'данных в средствах массовой информации, размещение в ' +
          'информационно-телекоммуникационных сетях или предоставление доступа к ' +
          'персональным данным каким-либо иным способом.'
      },
      {
        number: 13,
        text:
          'Трансграничная передача персональных данных — передача персональных ' +
          'данных на территорию иностранного государства органу власти иностранного ' +
          'государства, иностранному физическому или иностранному юридическому лицу.'
      },
      {
        number: 14,
        text:
          'Уничтожение персональных данных — любые действия, в результате ' +
          'которых персональные данные уничтожаются безвозвратно с невозможностью ' +
          'дальнейшего восстановления содержания персональных данных в информационной ' +
          'системе персональных данных и/или уничтожаются материальные ' +
          'носители персональных данных.'
      }
    ]
  },
  {
    title: 'Основные права и обязанности Оператора',
    texts: [
      {
        number: 1,
        text: 'Оператор имеет право:'
      },
      {
        text:
          'получать от субъекта персональных данных достоверные информацию и/или ' +
          'документы, содержащие персональные данные;'
      },
      {
        text:
          'в случае отзыва субъектом персональных данных согласия на обработку' +
          ' персональных данных, а также, направления обращения с требованием о ' +
          'прекращении обработки персональных данных, Оператор вправе продолжить ' +
          'обработку персональных данных без согласия субъекта персональных данных ' +
          'при наличии оснований, указанных в Законе о персональных данных;'
      },
      {
        text:
          'самостоятельно определять состав и перечень мер, необходимых и достаточных ' +
          'для обеспечения выполнения обязанностей, предусмотренных Законом о персональных ' +
          'данных и принятыми в соответствии с ним нормативными правовыми актами, ' +
          'если иное не предусмотрено Законом о персональных данных или ' +
          'другими федеральными законами.'
      },
      {
        number: 2,
        text: 'Оператор обязан:'
      },
      {
        text:
          'предоставлять субъекту персональных данных по его просьбе информацию, ' +
          'касающуюся обработки его персональных данных;'
      },
      {
        text:
          'организовывать обработку персональных данных в порядке, установленном ' +
          'действующим законодательством РФ;'
      },
      {
        text:
          'отвечать на обращения и запросы субъектов персональных данных и их ' +
          'законных представителей в соответствии с требованиями Закона о персональных данных;'
      },
      {
        text:
          'сообщать в уполномоченный орган по защите прав субъектов персональных ' +
          'данных по запросу этого органа необходимую информацию в течение 10 дней ' +
          'с даты получения такого запроса;'
      },
      {
        text:
          'публиковать или иным образом обеспечивать неограниченный доступ к ' +
          'настоящей Политике в отношении обработки персональных данных;'
      },
      {
        text:
          'принимать правовые, организационные и технические меры для защиты ' +
          'персональных данных от неправомерного или случайного доступа к ним, ' +
          'уничтожения, изменения, блокирования, копирования, предоставления, ' +
          'распространения персональных данных, а также от иных неправомерных действий ' +
          'в отношении персональных данных;'
      },
      {
        text:
          'прекратить передачу (распространение, предоставление, доступ) ' +
          'персональных данных, прекратить обработку и уничтожить персональные данные ' +
          'в порядке и случаях, предусмотренных Законом о персональных данных;'
      },
      { text: 'исполнять иные обязанности, предусмотренные Законом о персональных данных.' }
    ]
  },
  {
    title: 'Основные права и обязанности субъектов персональных данных',
    texts: [
      {
        number: 1,
        text: 'Субъекты персональных данных имеют право:'
      },

      {
        text:
          'получать информацию, касающуюся обработки его персональных данных, за ' +
          'исключением случаев, предусмотренных федеральными законами. Сведения ' +
          'предоставляются субъекту персональных данных Оператором в доступной форме, ' +
          'и в них не должны содержаться персональные данные, относящиеся к другим ' +
          'субъектам персональных данных, за исключением случаев, когда имеются законные ' +
          'основания для раскрытия таких персональных данных. Перечень информации и ' +
          'порядок ее получения установлен Законом о персональных данных;'
      },
      {
        text:
          'требовать от оператора уточнения его персональных данных, их блокирования ' +
          'или уничтожения в случае, если персональные данные являются неполными, ' +
          'устаревшими, неточными, незаконно полученными или не являются необходимыми ' +
          'для заявленной цели обработки, а также принимать предусмотренные законом ' +
          'меры по защите своих прав;'
      },
      {
        text:
          'выдвигать условие предварительного согласия при обработке персональных ' +
          'данных в целях продвижения на рынке товаров, работ и услуг;'
      },
      {
        text:
          'на отзыв согласия на обработку персональных данных, а также, на ' +
          'направление требования о прекращении обработки персональных данных;'
      },
      {
        text:
          'обжаловать в уполномоченный орган по защите прав субъектов персональных ' +
          'данных или в судебном порядке неправомерные действия или бездействие ' +
          'Оператора при обработке его персональных данных;'
      },
      { text: 'на осуществление иных прав, предусмотренных законодательством РФ.' },

      {
        number: 2,
        text: 'Субъекты персональных данных обязаны:'
      },
      { text: 'предоставлять Оператору достоверные данные о себе;' },
      {
        text: 'сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных.'
      },
      {
        number: 3,
        text:
          'Лица, передавшие Оператору недостоверные сведения о себе, либо ' +
          'сведения о другом субъекте персональных данных без согласия последнего, ' +
          'несут ответственность в соответствии с законодательством РФ.'
      }
    ]
  },
  {
    title: 'Принципы обработки персональных данных',
    texts: [
      {
        number: 1,
        text: 'Обработка персональных данных осуществляется на законной и справедливой основе.'
      },
      {
        number: 2,
        text:
          'Обработка персональных данных ограничивается достижением конкретных, ' +
          'заранее определенных и законных целей. Не допускается обработка персональных ' +
          'данных, несовместимая с целями сбора персональных данных.'
      },
      {
        number: 3,
        text:
          'Не допускается объединение баз данных, содержащих персональные данные, ' +
          'обработка которых осуществляется в целях, несовместимых между собой.'
      },
      {
        number: 4,
        text: 'Обработке подлежат только персональные данные, которые отвечают целям их обработки.'
      },
      {
        number: 5,
        text:
          'Содержание и объем обрабатываемых персональных данных соответствуют ' +
          'заявленным целям обработки. Не допускается избыточность обрабатываемых ' +
          'персональных данных по отношению к заявленным целям их обработки.'
      },
      {
        number: 6,
        text:
          'При обработке персональных данных обеспечивается точность персональных данных, ' +
          'их достаточность, а в необходимых случаях и актуальность по отношению к целям ' +
          'обработки персональных данных. Оператор принимает необходимые меры и/или ' +
          'обеспечивает их принятие по удалению или уточнению неполных или неточных данных.'
      },
      {
        number: 7,
        text:
          'Хранение персональных данных осуществляется в форме, позволяющей определить ' +
          'субъекта персональных данных, не дольше, чем этого требуют цели обработки ' +
          'персональных данных, если срок хранения персональных данных не установлен ' +
          'федеральным законом, договором, стороной которого, выгодоприобретателем или ' +
          'поручителем по которому является субъект персональных данных. Обрабатываемые ' +
          'персональные данные уничтожаются либо обезличиваются по достижении целей ' +
          'обработки или в случае утраты необходимости в достижении этих целей, если иное ' +
          'не предусмотрено федеральным законом.'
      }
    ]
  },
  {
    title: 'Цели обработки персональных данных',
    table: [
      {
        cell: 'Цель обработки',
        cell2: 'Связь с клиентом, оставившем заявку в форме обратной связи на сайте'
      },
      {
        cell: 'Персональные данные',
        cell2: 'Адрес электронной почты\nИмя пользователя'
      },
      {
        cell: 'Правовые основания',
        cell2:
          'Федеральный закон «Об информации, информационных технологиях и о ' +
          'защите информации» от 27.07.2006 N 149-ФЗ'
      },
      {
        cell: 'Виды обработки персональных данных',
        cell2: 'Отправка информационных писем на адрес электронной почты'
      }
    ]
  },
  {
    title: 'Условия обработки персональных данных',
    texts: [
      {
        number: 1,
        text:
          'Обработка персональных данных осуществляется с согласия субъекта ' +
          'персональных данных на обработку его персональных данных.'
      },
      {
        number: 2,
        text:
          'Обработка персональных данных необходима для достижения целей, ' +
          'предусмотренных международным договором Российской Федерации или законом, ' +
          'для осуществления возложенных законодательством Российской Федерации ' +
          'на оператора функций, полномочий и обязанностей.'
      },
      {
        number: 3,
        text:
          'Обработка персональных данных необходима для осуществления правосудия, ' +
          'исполнения судебного акта, акта другого органа или должностного лица, ' +
          'подлежащих исполнению в соответствии с законодательством Российской ' +
          'Федерации об исполнительном производстве.'
      },
      {
        number: 4,
        text:
          'Обработка персональных данных необходима для исполнения договора, ' +
          'стороной которого либо выгодоприобретателем или поручителем по которому ' +
          'является субъект персональных данных, а также для заключения договора ' +
          'по инициативе субъекта персональных данных или договора, по которому ' +
          'субъект персональных данных будет являться выгодоприобретателем или поручителем.'
      },
      {
        number: 5,
        text:
          'Обработка персональных данных необходима для осуществления прав ' +
          'и законных интересов оператора или третьих лиц либо для достижения ' +
          'общественно значимых целей при условии, что при этом не нарушаются ' +
          'права и свободы субъекта персональных данных.'
      },
      {
        number: 6,
        text:
          'Осуществляется обработка персональных данных, доступ неограниченного ' +
          'круга лиц к которым предоставлен субъектом персональных данных либо ' +
          'по его просьбе (далее — общедоступные персональные данные).'
      },
      {
        number: 7,
        text:
          'Осуществляется обработка персональных данных, подлежащих опубликованию ' +
          'или обязательному раскрытию в соответствии с федеральным законом.'
      }
    ]
  },
  {
    title: 'Порядок сбора, хранения, передачи и других видов обработки персональных данных',
    subtitle:
      'Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается ' +
      'путем реализации правовых, организационных и технических мер, необходимых для ' +
      'выполнения в полном объеме требований действующего законодательства в области ' +
      'защиты персональных данных.',
    texts: [
      {
        number: 1,
        text:
          'Оператор обеспечивает сохранность персональных данных и принимает все ' +
          'возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.'
      },
      {
        number: 2,
        text:
          'Персональные данные Пользователя никогда, ни при каких условиях не ' +
          'будут переданы третьим лицам, за исключением случаев, связанных с исполнением ' +
          'действующего законодательства либо в случае, если субъектом персональных данных ' +
          'дано согласие Оператору на передачу данных третьему лицу для исполнения ' +
          'обязательств по гражданско-правовому договору.'
      },
      {
        number: 3,
        text:
          'В случае выявления неточностей в персональных данных, Пользователь может ' +
          'актуализировать их самостоятельно, путем направления Оператору уведомление ' +
          'на адрес электронной почты Оператора info@ritm.studio с пометкой «Актуализация ' +
          'персональных данных».'
      },
      {
        number: 4,
        text:
          'Срок обработки персональных данных определяется достижением целей, для ' +
          'которых были собраны персональные данные, если иной срок не предусмотрен ' +
          'договором или действующим законодательством. Пользователь может в любой момент ' +
          'отозвать свое согласие на обработку персональных данных, направив Оператору ' +
          'уведомление посредством электронной почты на электронный адрес Оператора ' +
          'info@ritm.studio с пометкой «Отзыв согласия на обработку персональных данных».'
      },
      {
        number: 5,
        text:
          'Вся информация, которая собирается сторонними сервисами, в том числе ' +
          'платежными системами, средствами связи и другими поставщиками услуг, ' +
          'хранится и обрабатывается указанными лицами (Операторами) в соответствии с ' +
          'их Пользовательским соглашением и Политикой конфиденциальности. Субъект ' +
          'персональных данных и/или с указанными документами. Оператор не несет ' +
          'ответственность за действия третьих лиц, в том числе указанных в настоящем ' +
          'пункте поставщиков услуг.'
      },
      {
        number: 6,
        text:
          'Установленные субъектом персональных данных запреты на передачу ' +
          '(кроме предоставления доступа), а также на обработку или условия обработки ' +
          '(кроме получения доступа) персональных данных, разрешенных для распространения, ' +
          'не действуют в случаях обработки персональных данных в государственных, ' +
          'общественных и иных публичных интересах, определенных законодательством РФ.'
      },
      {
        number: 7,
        text:
          'Оператор при обработке персональных данных обеспечивает конфиденциальность ' +
          'персональных данных.'
      },
      {
        number: 8,
        text:
          'Оператор осуществляет хранение персональных данных в форме, позволяющей ' +
          'определить субъекта персональных данных, не дольше, чем этого требуют цели ' +
          'обработки персональных данных, если срок хранения персональных данных не ' +
          'установлен федеральным законом, договором, стороной которого, выгодоприобретателем ' +
          'или поручителем по которому является субъект персональных данных.'
      },
      {
        number: 9,
        text:
          'Условием прекращения обработки персональных данных может являться достижение ' +
          'целей обработки персональных данных, истечение срока действия согласия субъекта ' +
          'персональных данных, отзыв согласия субъектом персональных данных или требование ' +
          'о прекращении обработки персональных данных, а также выявление неправомерной ' +
          'обработки персональных данных.'
      }
    ]
  },
  {
    title: 'Перечень действий, производимых Оператором с полученными персональными данными',
    texts: [
      {
        number: 1,
        text:
          'Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, ' +
          'уточнение (обновление, изменение), извлечение, использование, передачу ' +
          '(распространение, предоставление, доступ), обезличивание, блокирование, ' +
          'удаление и уничтожение персональных данных.'
      },
      {
        number: 2,
        text:
          'Оператор осуществляет автоматизированную обработку персональных данных ' +
          'с получением и/или передачей полученной информации по ' +
          'информационно-телекоммуникационным сетям или без таковой.'
      }
    ]
  },
  {
    title: 'Трансграничная передача персональных данных',
    texts: [
      {
        number: 1,
        text:
          'Оператор до начала осуществления деятельности по трансграничной передаче ' +
          'персональных данных обязан уведомить уполномоченный орган по защите прав ' +
          'субъектов персональных данных о своем намерении осуществлять трансграничную ' +
          'передачу персональных данных (такое уведомление направляется отдельно от ' +
          'уведомления о намерении осуществлять обработку персональных данных).'
      },
      {
        number: 2,
        text:
          'Оператор до подачи вышеуказанного уведомления, обязан получить от ' +
          'органов власти иностранного государства, иностранных физических лиц, ' +
          'иностранных юридических лиц, которым планируется трансграничная передача ' +
          'персональных данных, соответствующие сведения.'
      }
    ]
  },
  {
    title: 'Конфиденциальность персональных данных',
    subtitle:
      'Оператор и иные лица, получившие доступ к персональным данным, обязаны не ' +
      'раскрывать третьим лицам и не распространять персональные данные без согласия ' +
      'субъекта персональных данных, если иное не предусмотрено федеральным законом.'
  },
  {
    title: 'Заключительные положения',
    texts: [
      {
        number: 1,
        text:
          'Пользователь может получить любые разъяснения по интересующим вопросам, ' +
          'касающимся обработки его персональных данных, обратившись к Оператору с ' +
          'помощью электронной почты info@ritm.studio.'
      },
      {
        number: 2,
        text:
          'В данном документе будут отражены любые изменения политики обработки ' +
          'персональных данных Оператором. Политика действует бессрочно до замены ' +
          'ее новой версией.'
      },
      {
        number: 3,
        text:
          'Актуальная версия Политики в свободном доступе расположена в сети ' +
          'Интернет по адресу https://ritm.studio и доступна во всплывающем окне при ' +
          'нажатии кнопки "Политика конфиденциальности персональных данных" в футере сайта.'
      }
    ]
  }
];

export default POLITICS;
