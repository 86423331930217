interface TBusiness {
  description: string;
}

const BUSINESS_ITEMS: TBusiness[] = [
  {
    description:
      'Мы специализируется на сложных решениях, связанных с глубокой аналитикой' +
      ' бизнес-процессов. Совместно с вами мы изучим ваш бизнес и предложим наиболее оптимальное' +
      ' и эффективное решение.'
  },
  {
    description:
      'Наша стратегия построена на долгосрочных партнерских отношениях. Если сейчас у вас нет' +
      ' сложных задач и вам требуется только простой сайт-визитка с обратной связью, мы с' +
      ' удовольствием поможем вам.'
  },
  {
    description:
      'На каждом этапе развития вашей компании мы поможем вам развивать собственную' +
      ' IT-экосистему актуальную для ваших текущих задач и использующую самый современный стек' +
      ' технологий.'
  }
];

export default BUSINESS_ITEMS;
